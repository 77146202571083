import React  from "react";
import { Button, Typography, Stack, Tooltip } from "@mui/material";
import HelpIcon from "@mui/icons-material/HelpCenter";

import ExternalLink from "./ExternalLink";
import supportCaptions from "../utils/supportCaptions.json";

export default (props) => {
  const { supportId, ...restProps } = props;
  const caption = supportCaptions[supportId];

  return (
    <Tooltip
      title={<>
        {caption}
        <ExternalLink
          href={`https://support.optimize-next.com/${supportId}`}
          label="詳細"
          iconSize="1.05rem"
          sx={{ whiteSpace: "nowrap" }}
        />
      </>}
      PopperProps={{ onClick: (e) => e.stopPropagation() }}
      slotProps={{
        tooltip: {
          sx:{
            maxWidth: 240,
            p: 1.5,
            boxShadow: 4,
            bgcolor: "background.hovered",
            typography: "body5",
          },
        },
      }}
    >
      <Stack
        component={Button}
        href={`https://support.optimize-next.com/${supportId}`}
        target="_blank"
        direction="row"
        alignItems="center"
        spacing={0.1}
        {...restProps}
      >
        <HelpIcon color="logo" sx={{ width: "1rem", height: "1rem" }} />
        <Typography variant="caption" color="logo.main" fontSize="0.675rem" sx={{ textDecoration: "underline", textWrap: "nowrap" }}>
          ヘルプ
        </Typography>
      </Stack>
    </Tooltip>
  );
};
