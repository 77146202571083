import React, { useRef }  from "react";
import { IconButton, Tooltip } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export default (props) => {
  const { onSelect, isTooltipOpen } = props;
  const inputRef = useRef(null);

  function handleChange(e) {
    onSelect(e.target.files);
    e.target.value = null;
  }

  return (
    <IconButton
      onClick={() => inputRef.current.click()}
      sx={{ padding: 0 }}
    >
      <Tooltip
        open={isTooltipOpen}
        title="画像ファイルを添付"
        placement="right"
        arrow
        PopperProps={{ sx: { cursor: "pointer" } }}
      >
        <AddCircleIcon />
      </Tooltip>
      <input
        ref={inputRef}
        onChange={handleChange}
        type="file"
        accept="image/*"
        multiple
        hidden
      />
    </IconButton>
  );
};
