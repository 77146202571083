import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, TableCell, TableContainer, Table, TableHead, TableRow, TableBody, Paper } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import Firestore from "../containers/Firestore";
import Drawer from "../containers/Drawer";
import AlertDialog from "../containers/AlertDialog";
import ArticleDrawerContent from "../components/ArticleDrawerContent";
import VStack from "../elements/VStack";
import MenuButton from "../elements/MenuButton";
import * as time from "../utils/time";

const title = "記事を新規作成", supportId = "";

export default () => {
  const navigate = useNavigate();
  const firestore = Firestore.useContainer();
  const { dataList: articleList = [] } = firestore.useArticleCol();

  const alertDialog = AlertDialog.useContainer();
  const drawer = Drawer.useContainer();

  function handleDelete(article) {
    alertDialog.open({
      title: `「${article.name}」を削除しますか？`,
      description: "この記事に関するすべてのデータが完全に削除されます。\n復元することはできません。",
      cancelable: true,
      actions: [
        {
          label: "削除",
          color: "error",
          onClick: () => {
            firestore.deleteArticle(article.id);
          },
        },
      ],
    });
  }

  function handleCreate() {
    drawer.open({
      title,
      supportId,
      btnLabel: "作成",
      ContentComponent: ArticleDrawerContent,
      onSuccess: (data) => {
        firestore.addArticle(data).then(() => {
          setTimeout(() => {
            drawer.close();
            navigate(`./${data.id}`);
          }, 300);
        });
      },
    });
  }

  return (
    <VStack width="96%" maxWidth="lg" py={3}>
      <Button variant="contained" color="primary" onClick={handleCreate}>
        新たな記事を作成する
      </Button>
      <TableContainer
        variant="outlined"
        component={Paper}
        sx={{ width: "100%" }}
      >
        <Table>
          <TableHead
            sx={{
              "& th": {
                fontSize: "small",
                color: "gray",
                padding: "8px 16px",
              },
            }}
          >
            <TableRow>
              <TableCell>記事名</TableCell>
              <TableCell>作成日</TableCell>
              <TableCell>記事ID</TableCell>
              <TableCell sx={{ width: 0 }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {articleList.map((article) => {
              const menuItems = [
                {
                  label: "削除",
                  Icon: DeleteForeverIcon,
                  color: "error",
                  onClick: () => handleDelete(article),
                },
              ];

              return (
                <TableRow
                  key={article.id}
                  onClick={() => navigate(`./${article.id}`)}
                  sx={{
                    cursor: "pointer",
                    "&:hover": { bgcolor: "background.focused" },
                  }}
                >
                  <TableCell sx={{ fontWeight: "bold" }}>
                    {article.name}
                  </TableCell>
                  <TableCell>{time.toDateString(article.createdAt)}</TableCell>
                  <TableCell>{article.id}</TableCell>
                  <TableCell>
                    <MenuButton items={menuItems}></MenuButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </VStack>
  );
};
