import React, { useEffect } from "react";
import { Stack, Container } from "@mui/material";

import Header from "../containers/Header";
import FirestoreArticle from "../containers/FirestoreArticle";
import Firestore from "../containers/Firestore";
import Drawer from "../containers/Drawer";
import ArticleIsPublicCard from "../components/ArticleIsPublicCard";
import ArticleNameCard from "../components/ArticleNameCard";
import ArticleBodyCard from "../components/ArticleBodyCard";

export default () => {
  const header = Header.useContainer();
  const firestoreArticle = FirestoreArticle.useContainer();
  const { docData: article = {} } = firestoreArticle;
  const firestore = Firestore.useContainer();
  const drawer = Drawer.useContainer();

  useEffect(() => {
    return () => header.setTitle(null);
  }, []);

  useEffect(() => {
    header.setTitle(article.name || "記事詳細");
  }, [article.name]);

  function handleUpdate(data) {
    firestore.updateArticle(article.id ,data).then(() => setTimeout(drawer.close, 300));
  }

  return (
    <Container>
      <Stack spacing={3} py={2}>
        <ArticleIsPublicCard {...{ article, handleUpdate }} />\
        <ArticleNameCard {...{ article, handleUpdate }} />
        <ArticleBodyCard {...{ article, handleUpdate }} />
      </Stack>
    </Container>
  );
};
