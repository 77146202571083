import React, { useEffect, useState } from "react";
import { useBeforeUnload } from "react-router-dom";
import { TextFieldElement, useFormContext } from "react-hook-form-mui";
import { Box, Container, IconButton, Stack } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

import SubmitButton from "./SubmitButton";
import FileInputButton from "./FileInputButton";
import FileCard from "./FileCard";

export default (props) => {
  const { handleSend, successCount, defaultInputBody, saveInputBody } = props;
  const { handleSubmit, reset, setValue, watch, getValues } = useFormContext();
  const currentFiles = watch("files") || [];
  const [isFocused, setIsFocused] = useState(false);

  useBeforeUnload((e) => {
    if (getValues("body") || getValues("files")?.length) {
      e.preventDefault();
    }
  });

  useEffect(() => {
    if (defaultInputBody) { setValue("body", defaultInputBody); }

    function onKeyDown (e) {
      switch (e.key) {
        case "Enter":
          if (e.metaKey || e.ctrlKey) { handleSubmit(handleSend)(); e.preventDefault(); }
          break;
        default:
          break;
      }
    }
    window.addEventListener("keydown", onKeyDown);

    return () => {
      saveInputBody(getValues("body"));
      window.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  useEffect(() => {
    if (successCount) { reset(); }
  }, [successCount]);
  
  function handleFileSelect(files) {
    setValue("files", [...currentFiles, ...files]);
  }

  function handleFileRemove(index) {
    setValue("files", currentFiles.filter((_, i) => i !== index));
  }

  return (
    <Stack alignItems="center" borderTop={1} borderColor="divider" bgcolor="background.card">
      <Stack component={Container} spacing={0.5} pt={0.5} pb={2} maxWidth="lg">
        <Stack direction="row" alignItems="center">
          <Stack flex={1} spacing={0.5} alignItems="baseline">
            <FileInputButton
              onSelect={handleFileSelect}
              isTooltipOpen={!currentFiles.length && !isFocused}
            />
            {!!currentFiles.length && (
              <Stack direction="row" spacing={2}>
                {currentFiles.map((file, i) => (
                  <Box key={i} sx={{ position: "relative", "&:hover .MuiIconButton-root": { visibility: "visible" } }}>
                    <FileCard item={file} />
                    <IconButton
                      onClick={(e) => { handleFileRemove(i); e.stopPropagation(); }}
                      sx={{
                        visibility: "hidden", position: "absolute", top: -12, right: -12, padding: 0,
                        opacity: 0.7, "&:hover": { opacity: 1 },
                      }}
                    >
                      <CancelIcon />
                    </IconButton>
                  </Box>
                ))}
              </Stack>
            )}
          </Stack>
          <SubmitButton label="送信" />
        </Stack>
        <TextFieldElement
          name="body"
          required
          multiline
          minRows={3}
          maxRows={15}
          fullWidth
          autoComplete="off"
          inputProps={{
            onFocus: () => setIsFocused(true),
            onBlur: () => setTimeout(() => setIsFocused(false), 300),
          }}
        />
      </Stack>
    </Stack>
  );
};
