import React, { useEffect, useState, useMemo } from "react";
import { Container, Stack } from "@mui/material";
import { FormContainer } from "react-hook-form-mui";

import Header from "../containers/Header";
import Firebase from "../containers/Firebase";
import Firestore from "../containers/Firestore";
import FirestoreContainer from "../containers/FirestoreContainer";
import FirestoreTopic from "../containers/FirestoreTopic";

import MessageBubble from "../elements/MessageBubble";
import MessageInput from "../elements/MessageInput";
import LoadingStack from "../elements/LoadingStack";
import { getRandomId } from "../utils/random";
import { arrayUnion } from "@firebase/firestore";

export default (props) => {
  const header = Header.useContainer();
  const { currentUser, isSuperUser } = Firebase.useContainer();
  const firestore = Firestore.useContainer();
  const firestoreContainer = FirestoreContainer.useContainer();
  const firestoreTopic = FirestoreTopic.useContainer();
  const { containerId, topicId, isService = isSuperUser } = props;
  const { docData: topic = {}, messageList = [] } = firestoreTopic;
  const { latestMessage, userIdArr = [], userLatestMessageIdObj = {}, uploadedFileNameArr = [] } = topic;

  const { docData: container = {}, currentUserRole, checkIsUpgradeRequired } = firestoreContainer;
  const { userObj = {} } = container;
  const readOnly = useMemo(() => currentUserRole === "viewer", [currentUserRole]);

  const [defaultInputBody, setDefaultInputBody] = useState("");
  const [successCount, setSuccessCount] = useState(0);
  const [fileObj, setFileObj] = useState({});

  useEffect(() => {
    setDefaultInputBody(sessionStorage.getItem(topicId));

    return () => {
      header.setTitle(null);
      header.setTitleChip(null);
      header.setTabs(null);
      header.setAlert(null);
    }
  }, []);

  useEffect(() => {
    header.setTitleChip(container.name ? { label: container.name, to: `/containers/${containerId}#support` } : null);
  }, [container.name]);

  useEffect(() => {
    header.setTitle(topic.name || "お問合せトピック");
  }, [topic.name]);

  useEffect(() => {
    header.setAlert(topic.notFound ? { children: "お問合せトピックが見つかりませんでした。" } : null);
  }, [topic.notFound]);

  useEffect(() => {
    if (!topic.id || !userIdArr.includes(currentUser?.uid)) { return; }
    const myLatestMessageId = userLatestMessageIdObj[currentUser?.uid];
    if (latestMessage && (myLatestMessageId !== latestMessage.id)) {
      firestoreTopic.update({ [`userLatestMessageIdObj.${currentUser?.uid}`]: latestMessage.id });
    }
  }, [latestMessage, userIdArr, userLatestMessageIdObj, currentUser?.uid]);

  useEffect(() => {
    uploadedFileNameArr.forEach((fileName) => {
      if (fileObj[fileName] === undefined) {
        firestore.getFile(`/containers/${containerId}/topics/${topicId}/${fileName}`)
          .then((file) => setFileObj((prev) => ({ ...prev, [fileName]: file })))
          .catch(() => setFileObj((prev) => ({ ...prev, [fileName]: null })));
      }
    });
  }, [uploadedFileNameArr]);

  useEffect(() => {
    if (!messageList.length) { return; }
    setTimeout(() => {
      const latestBubble = document.getElementById(messageList[0].id);
      if (latestBubble) {
        latestBubble.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
      }
    }, 300);
  }, [messageList.length]);

  if (!topic.id || topic.notFound) {
    return null;
  }

  if (!messageList.length) {
    return <LoadingStack />;
  }

  function handleSend({ files = [], ...data }, onSuccess) {
    if (isService) {
      data.createdByUserId = "support";
    } else if (readOnly || checkIsUpgradeRequired("addMessage")) {
      return;
    }
    const fileNameArr = files.map((file) => {
      const extension = file.name.split(".").at(-1);
      return `${getRandomId()}.${extension}`.toLowerCase();
    });
    firestoreTopic.addMessage({ ...data, fileNameArr }).then((messageId) => {
      setSuccessCount((prev) => prev + 1);
      files.forEach((file, i) => {
        const fileName = fileNameArr[i];
        firestore.uploadFile({
          file,
          path: `/containers/${containerId}/topics/${topicId}/${fileName}`,
          onSuccess: () => setFileObj((prev) => ({ ...prev, [fileName]: file })),
          onError: () => firestoreTopic.updateMessage(messageId, { failedFileNameArr: arrayUnion(fileName) }),
        });
      });
      onSuccess();
      saveInputBody(null);
    });
  }
  
  function saveInputBody(body) {
    if (body) {
      sessionStorage.setItem(topicId, body);
    } else {
      sessionStorage.removeItem(topicId);
    }
  }

  return (
    <Stack width="100%" position="absolute" top={0} bottom={0} right={0} left={0}>
      <Stack direction="column-reverse" flex={1} spacing={4} p={2} overflow="scroll" maxWidth="lg" component={Container}>
        {messageList.map((item) => (
          <MessageBubble key={item.id} {...{ item, userObj, fileObj }} />
        ))}
      </Stack>
      {!readOnly && (
        <FormContainer onSuccess={handleSend}>
          <MessageInput {...{ handleSend, successCount, defaultInputBody, saveInputBody }} />
        </FormContainer>
      )}
    </Stack>
  );
}
