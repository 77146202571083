import { createContainer } from "unstated-next";
import { doc } from "firebase/firestore";

import Firestore from "./Firestore";

export default createContainer(({ articleId }) => {
  const { articleColRef, useDocData, updateDocData } = Firestore.useContainer();

  const articleDocRef = doc(articleColRef, articleId);

  const { data: docData = {} } = useDocData(articleDocRef);

  function update(data, operation = "記事を更新") {
    return updateDocData(articleDocRef, data, operation);
  }

  return { docData, update };
});