import { useEffect, useState } from "react";
import { createContainer } from "unstated-next";

export default createContainer(() => {
  const [isInstalled, setIsInstalled] = useState(false);
  const [version, setVersion] = useState();

  useEffect(() => {
    addMessageListener((data) => {
      switch (data.action) {
        case "init":
          setIsInstalled(true);
          setVersion(data.version);
          break;
        default:
          break;
      }
    });
  }, []);

  function postMessage(data) {
    window.postMessage({
      toolId: "optimize-next",
      ...data,
    }, "*");
  }

  function addMessageListener(callback) {
    window.addEventListener("message", (event) => {
      const { toolId, ...data } = event?.data || {};
      // if ((event.source !== window) || (toolId !== "optimize-next")) { return; }
      if (toolId !== "optimize-next") { return; }
      callback(data);
    });
  }

  return { isInstalled, version, postMessage, addMessageListener };
});
