import { createTheme } from "@mui/material";

export default createTheme({
  palette: {
    white: {
      main: "#ffffff",
      light: "#ffffff",
      dark: "#ffffff",
      contrastText: "#333333",
    },
    gray: {
      main: "#909090",
      light: "#c0c0c0",
      dark: "#909090",
      contrastText: "#ffffff",
    },
    darkGray: {
      main: "#444",
      light: "#444",
      dark: "#444",
      contrastText: "#ffffff",
    },
    lightGray: {
      main: "#bdbdbd",
      light: "#bdbdbd",
      dark: "#bdbdbd",
      contrastText: "#333333",
    },
    background: {
      paper: "#ffffff",
      card: "#fcfdfd",
      default: "#f8f9fa",
      hovered: "#f3f4f5",
      focused: "#ecf2fc",
    },
    border: {
      input: "rgba(0, 0, 0, 0.23)",
      focused: "#1976d2",
      tree: "#8086a1",
      focusedTree: "#72abe2",
    },
    strong: {
      main: "#303865",
    },
    title: {
      main: "#303846",
    },
    logo: {
      main: "#4D50D7",
      light: "#7F81F1",
      dark: "#5C5FFF",
      title: "#9395DD",
      contrastText: "#ffffff",
    },
    free: {
      main: "#909090",
      contrastText: "#ffffff",
    },
    starter: {
      main: "#B0C000",
      contrastText: "#ffffff",
    },
    basic: {
      main: "#DAAD00",
      contrastText: "#ffffff",
    },
    premium: {
      main: "#BD8800",
      dark: "#BE9002",
      contrastText: "#ffffff",
    },
  },
  typography: {
    h1: {
      fontSize: "min(3rem, 8.5vw)",
      lineHeight: "normal",
      letterSpacing: "0.4rem",
      fontWeight: "bold",
      color: "#303846",
      marginBottom: "1.8rem",
      wordBreak: "keep-all",
      "& span": {
        display: "inline-block",
      },
    },
    h2: {
      fontSize: "min(2.2rem, 6.4vw)",
      lineHeight: "normal",
      letterSpacing: "0.2rem",
      fontWeight: "bold",
      color: "#303846",
      marginTop: "0.5rem",
      marginBottom: "2rem",
      wordBreak: "keep-all",
      "& span": {
        display: "inline-block",
      },
    },
    h3: {
      fontSize: "min(1.2rem, 4vw)",
      lineHeight: "normal",
      letterSpacing: "0.06rem",
      fontWeight: "bold",
      color: "#303858",
      wordBreak: "keep-all",
      "& span": {
        display: "inline-block",
      },
    },
    h4: {
      fontSize: "min(1.05rem, 3.75vw)",
      lineHeight: "normal",
      letterSpacing: "0.05rem",
      fontWeight: "bold",
      color: "#303858",
    },
    h5: {
      fontSize: "0.95rem",
      lineHeight: "normal",
      letterSpacing: "0.05rem",
      fontWeight: "bold",
      color: "#555",
    },
    h6: {
      fontSize: "0.875rem",
      lineHeight: "1.5rem",
      letterSpacing: "0.04rem",
      fontWeight: "bold",
      color: "#565656",
      whiteSpace: "nowrap",
    },
    caption: {
      color: "#555",
      lineHeight: "normal",
    },
    body2: {
      minWidth: "4rem",
    },
    body3: {
      fontSize: "1.05rem",
      lineHeight: 1.6,
      letterSpacing: "0.06rem",
      color: "#454545",
      "& span": {
        display: "inline-block",
      },
      "& strong": {
        color: "#303865",
      },
      "& i": {
        fontSize: "0.825rem",
        color: "#666",
      },
    },
    body4: {
      fontSize: "0.95rem",
      lineHeight: 1.6,
      letterSpacing: "0.05rem",
      color: "#454545",
      "& strong": {
        color: "#303865",
      },
    },
    body5: {
      fontSize: "0.8rem",
      color: "#454545",
      whiteSpace: "pre-wrap",
      "& strong": {
        color: "#303865",
      },
    },
    body6: {
      fontSize: "0.75rem",
      lineHeight: 1,
      color: "#454545",
      whiteSpace: "pre-wrap",
    },
    footer: {
      wordBreak: "keep-all",
      fontSize: "0.7rem",
      letterSpacing: "0.07rem",
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff",
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          transition: "inherit !important",
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          paddingInline: "16px",
          paddingBottom: "16px",
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        content: {
          maxWidth: "100%",
          overflow: "hidden",
        },
        avatar: {
          marginRight: "0.5rem",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          minWidth: "inherit !important",
          "&:not(:first-of-type, :last-of-type)": {
            paddingInline: "8px !important",
          },
        },
        head: {
          color: "#565656",
          textWrap: "nowrap",
        },
        body: {
          color: "#454545",
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          "& .MuiTableRow-root:last-of-type .MuiTableCell-root": {
            borderBottom: "none",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          lineHeight: "2rem",
          fontWeight: "normal",
        },
        labelMedium: {
          fontSize: "0.85rem",
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          border: "1px solid #ccc",
          transition: "none",
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          lineHeight: "normal",
          letterSpacing: "0.025rem",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          "& button:last-of-type": {
            fontWeight: "bold",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        popper: {
          transition: "none",
        },
        arrow: {
          transition: "none",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            backgroundColor: "inherit !important",
          },
        },
      },
    },
  },
  zIndex: {
    snackbar: 1200,
  },
});
