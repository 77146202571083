import React, { useState }  from "react";
import { Avatar, Button, Dialog, Skeleton } from "@mui/material";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";

export default (props) => {
  const { item, size = 84 } = props;
  const [isOpen, setIsOpen] = useState(false);

  if (item === undefined) {
    return <Skeleton width={size} height={size} variant="rounded" animation="wave" />;
  }

  const src = item ? URL.createObjectURL(item) : "";
  
  // const renderContent = (p) => {
  //   if (item.type.startsWith("image/")) {
  //     return <img src={src} alt="プレビュー" {...p} />;
  //   }
  //   return <embed src={src} type={item.type} {...p} />;
  // };

  return (
    <>
      <Avatar
        src={src}
        disabled={!src}
        onClick={() => setIsOpen(true)}
        variant="square"
        component={Button}
        sx={{
          width: size, height: size, p: 0, boxSizing: "content-box",
          backgroundColor: "rgba(0, 0, 0, 0.5)", borderRadius: "4px", borderColor: "#9D9D9D",
          position: "relative",
          "&:hover": {
            "&:before": {
              position: "absolute", top: 0, right: 0, bottom: 0, left: 0,
              content: '""', zIndex: 100, backgroundColor: "rgba(0, 0, 0, 0.1)",
            },
          },
        }}
      >
        <ImageNotSupportedIcon />
      </Avatar>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        sx={{ zIndex: 5000 }}
        maxWidth="xl"
        PaperProps={{ sx: { backgroundColor: "rgba(0, 0, 0, 0.5)", p: 2 } }}
      >
        <img src={src} alt="プレビュー" />
      </Dialog>
    </>
  );
};
