import React from "react";
import { Stack } from "@mui/material";

import Firestore from "../containers/Firestore";

import VStack from "../elements/VStack";
import TopicCard from "../components/TopicCard";

export default () => {
  const firestore = Firestore.useContainer();
  const { dataList: topicList = [] } = firestore.useTopicCol();
  
  return (
    <VStack width="96%" maxWidth="lg" py={3}>
      <Stack spacing={2} width="100%">
        {topicList.map((topic) => (
          <TopicCard
            key={topic.id}
            item={topic}
            isService
          />
        ))}
      </Stack>
    </VStack>
  );
}
