import React, { useEffect } from 'react';
import { TextFieldElement, useFormContext } from "react-hook-form-mui";
import { Stack } from "@mui/material";

export default (props) => {
  const { defaultValue } = props;
  const { reset } = useFormContext();

  useEffect(() => {
    if (defaultValue) { reset(defaultValue); }
    return () => reset({});
  }, []);

  return (
    <Stack spacing={4} p={4}>
      <TextFieldElement
        name="name"
        label="記事名"
        required
        fullWidth
        autoFocus
        placeholder="記事名"
        autoComplete="off"
      />
    </Stack>
  )
}
