import React from "react";
import { useParams, Navigate } from "react-router-dom";

import Firebase from "../containers/Firebase";
import FirestoreTemplate from "../containers/FirestoreTemplate";
import FirestoreContainer from "../containers/FirestoreContainer";
import FirestoreExperience from "../containers/FirestoreExperience";
import FirestoreTopic from "../containers/FirestoreTopic";
import FirestoreSession from "../containers/FirestoreSession";
import SupportDrawer from "../containers/SupportDrawer";

import Top from "./Top";
import Plans from "./Plans";
import Terms from "./Terms";
import Policy from "./Policy";
import Compliance from "./Compliance";
import TemplateList from "./TemplateList";
import TemplateDetail from "./TemplateDetail";
import ContainerList from "./ContainerList";
import ContainerDetail from "./ContainerDetail";
import ExperienceDetail from "./ExperienceDetail";
import TopicDetail from "./TopicDetail";
import SessionDetail from "./SessionDetail";
import ServiceTopicList from "./ServiceTopicList";
import ServiceArticleList from "./ServiceArticleList";
import ServiceArticleDetail from "./ServiceArticleDetail";
import FirestoreArticle from "../containers/FirestoreArticle";
// import ArticleList from "./ArticleList";
// import ArticleDetail from "./ArticleDetail";

export default [
  {
    path: "/",
    element: <Top />,
  },
  {
    path: "/plans",
    element: <Plans />,
  },
  {
    path: "/terms",
    element: <Terms />,
  },
  {
    path: "/policy",
    element: <Policy />,
  },
  {
    path: "/compliance",
    element: <Compliance />,
  },
  {
    path: "/templates",
    element: <TemplateList />,
  },
  {
    path: "/templates/:templateId",
    Component: () => {
      const { templateId } = useParams();
      return (
        <FirestoreTemplate.Provider initialState={{ templateId }}>
          <TemplateDetail />
        </FirestoreTemplate.Provider>
      );
    },
  },
  {
    path: "/containers",
    element: <ContainerList />,
  },
  {
    path: "/containers/:containerId",
    Component: () => {
      const { containerId } = useParams();
      return (
        <FirestoreContainer.Provider initialState={{ containerId }}>
          <SupportDrawer.Provider initialState={{ containerId }}>
            <ContainerDetail />
          </SupportDrawer.Provider>
        </FirestoreContainer.Provider>
      );
    },
  },
  {
    path: "/containers/:containerId/experiences/:experienceId",
    Component: () => {
      const { containerId, experienceId } = useParams();
      return (
        <FirestoreContainer.Provider initialState={{ containerId }}>
          <FirestoreExperience.Provider initialState={{ experienceId }}>
            <SupportDrawer.Provider
              initialState={{ containerId, experienceId }}
            >
              <ExperienceDetail {...{ containerId, experienceId }} />
            </SupportDrawer.Provider>
          </FirestoreExperience.Provider>
        </FirestoreContainer.Provider>
      );
    },
  },
  {
    path: "/containers/:containerId/topics/:topicId",
    Component: () => {
      const { containerId, topicId } = useParams();
      return (
        <FirestoreContainer.Provider initialState={{ containerId }}>
          <FirestoreTopic.Provider initialState={{ topicId }}>
            <TopicDetail {...{ containerId, topicId }} />
          </FirestoreTopic.Provider>
        </FirestoreContainer.Provider>
      );
    },
  },
  {
    path: "/containers/:containerId/sessions/:sessionId",
    Component: () => {
      const { containerId, sessionId } = useParams();
      return (
        <FirestoreSession.Provider initialState={{ containerId, sessionId }}>
          <SessionDetail />
        </FirestoreSession.Provider>
      );
    },
  },
  // {
  //   path: "/articles",
  //   element: <ArticleList />,
  // },
  // {
  //   path: "/articles/:articleId",
  //   Component: () => {
  //     const { articleId } = useParams();
  //     return (
  //       <FirestoreArticle.Provider initialState={{ articleId }}>
  //         <ArticleDetail />
  //       </FirestoreArticle.Provider>
  //     );
  //   },
  // },
  {
    path: "/service/topics",
    Component: () => {
      const { isSuperUser } = Firebase.useContainer();
      if (!isSuperUser) { return <Navigate replace to="/" />; }
      return <ServiceTopicList />;
    },
  },
  {
    path: "/service/containers/:containerId/topics/:topicId",
    Component: () => {
      const { containerId, topicId } = useParams();
      const { isSuperUser } = Firebase.useContainer();
      if (!isSuperUser) {
        return <Navigate replace to="/" />;
      }
      return (
        <FirestoreContainer.Provider initialState={{ containerId }}>
          <FirestoreTopic.Provider initialState={{ topicId }}>
            <TopicDetail {...{ containerId, topicId }} isService />
          </FirestoreTopic.Provider>
        </FirestoreContainer.Provider>
      );
    },
  },
  {
    path: "/service/articles",
    Component: () => {
      const { isSuperUser } = Firebase.useContainer();
      if (!isSuperUser) {
        return <Navigate replace to="/" />;
      }
      return <ServiceArticleList />;
    },
  },
  {
    path: "/service/articles/:articleId",
    Component: () => {
      const { articleId } = useParams();
      const { isSuperUser } = Firebase.useContainer();
      if (!isSuperUser) {
        return <Navigate replace to="/" />;
      }
      return (
        <FirestoreArticle.Provider initialState={{ articleId }}>
          <ServiceArticleDetail />
        </FirestoreArticle.Provider>
      );
    },
  },
  {
    path: "/containers/:containerId/experiences/:experienceId/*",
    Component: () => {
      const { containerId, experienceId } = useParams();
      return (
        <Navigate
          replace
          to={`/containers/${containerId}/experiences/${experienceId}`}
        />
      );
    },
  },
  {
    path: "/containers/:containerId/topics/:topicId/*",
    Component: () => {
      const { containerId, topicId } = useParams();
      return (
        <Navigate replace to={`/containers/${containerId}/topics/${topicId}`} />
      );
    },
  },
  {
    path: "/containers/:containerId/sessions/:sessionId/*",
    Component: () => {
      const { containerId, sessionId } = useParams();
      return (
        <Navigate
          replace
          to={`/containers/${containerId}/sessions/${sessionId}`}
        />
      );
    },
  },
  {
    path: "/containers/:containerId/*",
    Component: () => {
      const { containerId } = useParams();
      return <Navigate replace to={`/containers/${containerId}`} />;
    },
  },
  {
    path: "/*",
    element: <Navigate replace to="/" />,
  },
];
