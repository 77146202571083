import { useEffect } from "react";
import { createContainer as createUnstatedContainer } from "unstated-next";
import { getApp } from "firebase/app";
import { getFirestore, doc, collection, collectionGroup, addDoc, setDoc, updateDoc, deleteDoc, serverTimestamp, orderBy, query, where, onSnapshot } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getBlob } from "firebase/storage";
import { useDocumentData, useCollectionData } from "react-firebase-hooks/firestore";

import AlertDialog from "./AlertDialog";
import Snackbar from "./Snackbar";
import Firebase from "./Firebase";


export default createUnstatedContainer(({ currentUser }) => {
  const alertDialog = AlertDialog.useContainer();
  const snackbar = Snackbar.useContainer();
  const firebase = Firebase.useContainer();
  const { uid, ...userData } = currentUser || {};

  const app = getApp();
  const db = getFirestore();
  const storage = getStorage(app);
  const assetStorage = getStorage(app, "gs://assets.optimize-next.com");
  
  async function uploadFile({ file, path, metadata, onSuccess, onError, isAsset }) {
    if (!uid) { return firebase.openSignInAlert(); }
    const title = "ファイルをアップロード中...";
    const fileSnackbar = snackbar.open({ title, severity: "info", autoHideDuration: null }, path);
    return uploadBytesResumable(ref(isAsset ? assetStorage : storage, path), file, metadata ? { customMetadata: metadata } : undefined)
      .on("state_changed",
        (snapshot) => {
          const progress = ((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          fileSnackbar.update({ title: `${title}（${Math.round(progress)}%）` });
        },
        () => {
          onError?.();
          fileSnackbar.update({ title: "ファイルのアップロードに失敗しました", severity: "error" });
        },
        () => {
          onSuccess();
          setTimeout(() => {
            fileSnackbar.update({ title: "ファイルをアップロードしました", severity: "success", autoHideDuration: 5000 });
          }, 300);
        }
      );
  }

  // async function getAllFiles(path) {
  //   return listAll(ref(storage, path))
  //     .then((res) => {
  //       return Promise.all(res.items.map(async (itemRef) => {
  //         const blob = await getBlob(itemRef);
  //         return { name: itemRef.name, blob };
  //       }));
  //     }).catch((error) => {
  //       console.error(error);
  //     });
  // }

  async function getFile(path) {
    return getBlob(ref(storage, path));
  }

  async function listenDocData(docRef, callback) {
    onSnapshot(docRef.withConverter(converter), (snapshot) => {
      callback(snapshot.data());
    });
  }

  function useDocData(docRef, isErrorDisabled, isLoadingDisabled) {
    const { path } = docRef;
    const [data, loading, error, snapshot] = useDocumentData(docRef.withConverter(converter));

    useEffect(() => {
      if (loading) {
        if (!alertDialog.getIsOpen(path + "-loading") && !isLoadingDisabled) {
          alertDialog.open({ isLoading: true }, path + "-loading");
        }
      } else if (!isErrorDisabled) {
        if (error) {
          if (uid) {
            console.error(error);
            snackbar.open({ operation: "データを取得", isError: true }, path + "-error");
          }
        } else if (!snapshot?.exists()) {
          snackbar.open({ title: "データが存在しません", isError: true }, path + "-notfound");
        }
      }

      if (!loading && alertDialog.getIsOpen(path + "-loading")) {
        alertDialog.close(path + "-loading");
      }
      if (!error) {
        snackbar.close(path + "-error");
      }
      if (snapshot?.exists()) {
        snackbar.close(path + "-notfound");
      }
    }, [snapshot, loading, error]);
  
    return {
      data: (!snapshot || snapshot.exists()) ? data : { notFound: true },
      loading,
      error,
    };
  }

  function useColData(colRef, ...options) {
    const { path } = colRef;
    options.push(orderBy("createdAt", "desc"));
    const [dataList, loading, error, snapshot] = useCollectionData(query(colRef, ...options).withConverter(converter));

    useEffect(() => {
      if (loading) {
        if (!alertDialog.getIsOpen(path + "-loading")) {
          alertDialog.open({ isLoading: true }, path + "-loading");
        }
      } else if (error) {
        if (uid) {
          console.error(error);
          snackbar.open({ operation: "データを取得", isError: true }, path + "-error");
        }
      }

      if (!loading && alertDialog.getIsOpen(path + "-loading")) {
        alertDialog.close(path + "-loading");
      }
      if (!error && snackbar.getIsOpen(path + "-error")) {
        snackbar.close(path + "-error");
      }
    }, [loading, error]);

    return {
      dataList,
      isLocal: snapshot?.metadata?.hasPendingWrites,
      loading,
      error,
    };
  }

  async function addDocData(colRef, data, operation, timeout = 0) {
    if (!uid) { return firebase.openSignInAlert(); }
    const loadingDialog = operation ? alertDialog.open({ operation, isLoading: true }) : null;
    return new Promise((resolve) => {
      addDoc(colRef, {
        createdByUserId: uid,
        ...getParsedData(data),
        createdAt: serverTimestamp(),
      })
        .then((ret) => {
          resolve(ret.id);
          if (operation) {
            setTimeout(() => loadingDialog.close(), timeout);
            snackbar.open({ operation });
          }
        })
        .catch((e) => {
          console.error(e);
          resolve(false);
          if (operation) {
            loadingDialog.close();
            snackbar.open({ operation, isError: true });
          }
        });
    });
  }

  async function setDocData(docRef, data, operation) {
    if (!uid) { return firebase.openSignInAlert(); }
    const loadingDialog = operation ? alertDialog.open({ operation, isLoading: true }) : null;
    return new Promise((resolve) => {
      setDoc(docRef, {
        createdByUserId: uid,
        ...getParsedData(data),
        createdAt: serverTimestamp(),
      })
        .then(() => {
          resolve(true);
          if (operation) {
            loadingDialog.close();
            snackbar.open({ operation });
          }
        })
        .catch((e) => {
          console.error(e);
          resolve(false);
          if (operation) {
            loadingDialog.close();
            snackbar.open({ operation, isError: true });
          }
        });
    });
  }

  async function updateDocData(docRef, data, operation) {
    if (!uid) { return firebase.openSignInAlert(); }
    const loadingDialog = operation ? alertDialog.open({ operation, isLoading: true }) : null;
    return new Promise((resolve) => {
      updateDoc(docRef, {
        ...getParsedData(data),
        updatedByUserId: uid,
        updatedAt: serverTimestamp(),
      })
        .then(() => {
          resolve(true);
          if (operation) {
            loadingDialog.close();
            snackbar.open({ operation });
          }
        })
        .catch((e) => {
          console.error(e);
          resolve(false);
          if (operation) {
            loadingDialog.close();
            snackbar.open({ operation, isError: true });
          }
        });
    });
  }

  async function deleteDocData(docRef, operation) {
    if (!uid) { return; }
    const loadingDialog = operation ? alertDialog.open({ operation, isLoading: true }) : null;
    return new Promise((resolve) => {
      deleteDoc(docRef)
        .then(() => {
          resolve(true);
          if (operation) {
            loadingDialog.close();
            snackbar.open({ operation });
          }
        })
        .catch((e) => {
          console.error(e);
          resolve(false);
          if (operation) {
            loadingDialog.close();
            snackbar.open({ operation, isError: true });
          }
        });
    });
  }

  const containerColRef = collection(db, "containers");

  function useMyContainerCol() {
    return useColData(containerColRef, where("userIdArr", "array-contains", uid || ""));
  }

  async function addContainer(data) {
    return addDocData(containerColRef, {
      ...data,
      gtmStatus: "creating",
      userIdArr: [uid],
      userRoleObj: { [uid]: "owner" },
      userObj: { [uid]: userData },
    }, "コンテナを作成")
      .then((ret) => {
        firebase.sendEvent("create_container");
        return ret;
      });
  }

  async function addContainerRequest(containerId, data) {
    return addDocData(collection(containerColRef, containerId, "requests"), {
      ...data,
      status: "processing",
      isActive: true,
    }, "リクエストを送信");
  }

  const topicColGroupRef = collectionGroup(db, "topics");

  function useTopicCol(){
    return useColData(topicColGroupRef);
  }

  const templateColRef = collection(db, "templates");

  function useTemplateCol() {
    return useColData(templateColRef, where("accessMode", "==", "public"), orderBy("importedCount", "desc"));
  }

  function useMyTemplateCol() {
    return useColData(templateColRef, where("createdByUserId", "==", uid || ""));
  }

  async function addTemplate(data) {
    return addDocData(templateColRef, { ...data, importedCount: 0 }, "テンプレートを作成")
      .then((ret) => {
        firebase.sendEvent("post_template");
        return ret;
      });
  }

  async function updateTemplate(templateId, data) {
    return updateDocData(doc(templateColRef, templateId), data, "テンプレートを更新");
  }

  async function deleteTemplate(templateId) {
    return deleteDocData(doc(templateColRef, templateId), "テンプレートを削除");
  }

  //記事関連
  const articleColRef = collection(db, "articles");
  
  //記事の取得
  function useArticleCol(){
    return useColData(articleColRef);
  }

  function usePublicArticleCol(){
    return useColData(articleColRef, where("isPublic", "==", true));
  }

  //記事の追加・addではなくsetで処理
  async function addArticle({ id, ...data }) {
    return setDocData(doc(articleColRef, id), { ...data, isPublic: false }, "記事を作成");
  }
  
  //記事の更新
  async function updateArticle(articleId, data) {
    return updateDocData(doc(articleColRef, articleId), data, "記事を更新");
  }

  //記事の削除
  async function deleteArticle(articleId) {
    return deleteDocData(doc(articleColRef, articleId), "記事を削除");
  }



  return {
    listenDocData, useDocData, useColData,
    addDocData, setDocData, updateDocData, deleteDocData,
    uploadFile, getFile,
    containerColRef, useMyContainerCol, addContainer, addContainerRequest,
    useTopicCol,
    templateColRef, useTemplateCol, useMyTemplateCol, addTemplate, updateTemplate, deleteTemplate, 
    articleColRef, useArticleCol, usePublicArticleCol, addArticle, updateArticle, deleteArticle,
  };
});

const converter = { fromFirestore: (snapshot) => ({ ...snapshot.data(), id: snapshot.id, path: snapshot.ref?.path }) };

function getParsedData(data, nested) {
  if (typeof data !== "object") {
    return data;
  }
  if (Array.isArray(data)) {
    data.forEach((x, i) => {
      data[i] = getParsedData(x, true);
    });
    return data;
  }
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      if ((data[key] === undefined) || (!nested && (data[key]?.toDate || ["id", "path"].includes(key)))) {
        delete data[key];
      } else if (key === "timestampKeyArr") {
        data[key].forEach((timestampKey) => { data[timestampKey] = serverTimestamp(); });
        delete data[key];
      } else {
        data[key] = getParsedData(data[key], true);
      }
    }
  }
  return data;
}
