import React, { useEffect } from "react";
import { TextFieldElement, useFormContext } from "react-hook-form-mui";
import { Stack } from "@mui/material";

export default (props) => {
  const { defaultValue } = props;
  const { reset } = useFormContext();

  useEffect(() => {
    if (defaultValue) {
      reset(defaultValue);
    }
    return () => reset({});
  }, []);

  // useEffect(() => {
  //   const subscription = watch(({ defaultUrl }) => {
  //     if (defaultUrl) {
  //       if (!getIsUrl(defaultUrl)) {
  //         setError("defaultUrl", {
  //           type: "manual",
  //           message: "有効なURLを入力してください",
  //         });
  //       } else {
  //         clearErrors("defaultUrl");
  //       }
  //     }
  //   });
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  return (
    <Stack spacing={4} p={4}>
      <TextFieldElement
        name="name"
        label="記事名"
        required
        fullWidth
        autoFocus
        placeholder="記事名"
        autoComplete="off"
      />
      <TextFieldElement
        name="id"
        label="記事のパス"
        required
        fullWidth
        placeholder="記事のパス"
      />
    </Stack>
  );
};
