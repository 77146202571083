import React from "react";
import { Stack, Typography } from "@mui/material";
import SubjectIcon from "@mui/icons-material/Subject";

import Drawer from "../containers/Drawer";
import ArticleBodyDrawerContent from "./ArticleBodyDrawerContent";
import BuilderCard from "../elements/BuilderCard";
import ActionButton from "../elements/ActionButton";

const title = "記事本文";

export default (props) => {
  const { article, handleUpdate } = props;
  const drawer = Drawer.useContainer();

  function handleEdit() {
    drawer.open({
      title,
      btnLabel: "更新",
      defaultValue: article,
      ContentComponent: ArticleBodyDrawerContent,
      onSuccess: handleUpdate,
    });
  }

  return (
    <BuilderCard
      {...{ title }}
      color="success"
      Icon={SubjectIcon}
      renderItem={() => (
        <Stack direction="row" spacing={4} alignItems="center">
          <Stack flex={4}>
            <Typography variant="body2">{article.body}</Typography>
          </Stack>
          <Stack
            flex={1}
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="flex-end"
          >
            <ActionButton type="edit" onClick={handleEdit} />
          </Stack>
        </Stack>
     )}
    />
  );
};
