import React  from "react";
import { Link } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export default (props) => {
  const { href, label = href, isIconHidden, fontSize = "inherit", iconSize, sx = {}, } = props;
  return (
    <Link
      href={href}
      component={href ? "a" : "span"}
      target={href ? "_blank" : undefined}
      sx={{
        width: "fit-content",
        lineBreak: "anywhere",
        whiteSpace: "normal",
        fontWeight: "normal",
        fontSize,
        ...sx,
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {label}
      {!isIconHidden && (<OpenInNewIcon fontSize="small" sx={{ verticalAlign: "sub", ml: 0.5, width: iconSize, height: iconSize }} />)}
    </Link>
  );
};
