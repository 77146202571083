import React from "react";
import { Stack, Switch, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

import BuilderCard from "../elements/BuilderCard";

const title = "記事の公開状況";

export default (props) => {
  const { article, handleUpdate } = props;

  return (
    <BuilderCard
      {...{ title }}
      color={article.isPublic ? "info" : "gray"}
      Icon={VisibilityIcon}
      renderItem={() => (
        <Stack
          direction="row"
          alignItems="center"
          useFlexGap
          flexWrap="wrap"
          spacing={1}
        >
          <Switch
            checked={article.isPublic}
            onChange={() => handleUpdate({ isPublic: !article.isPublic }) }
          />
          <Typography variant="body2">記事を公開する</Typography>
        </Stack>
      )}
    />
  );
};
